<template>
  <!--
    データセット一覧の上部
  -->
  <page-top :title="summary.title" :iconName="summary.iconName">
    <div class="top-function-wrap">
      <div class="top-function-main">
        <button-main
          v-if="projectId"
          class="top-function-button"
          :text="$t('trainedAiList.createNewAi.useRecipe')"
          type="emphasis"
          :disabled="allTrainedAILoading"
          @click="$emit('add-new-trained-ai')"
        />
        <fuse-search
          v-if="!allTrainedAILoading"
          class="top-function-search"
          :placeholder="$t('trainedAiList.search')"
          :value="trainedAiFiltered"
          :option="option"
          @input="$emit('input', $event)"
        />
      </div>
      <div class="top-function-menus">
        <div class="top-function-menus-inner">
          <menu-list
            :checked="checked"
            :menus="menus"
            :isMenuShow="checked.length > 0"
            @menu-click="
              $emit($event.activeMenu + '-menu-click', { target: checked })
            "
          />
        </div>
        <div class="top-function-layout-menu-wrap">
          <div
            class="top-function-layout-menu-inner"
            :class="
              layoutType === 'grid'
                ? 'top-function-layout-menu-inner-active'
                : ''
            "
          >
            <icons
              iconName="grid"
              isButton
              class="top-function-layout-menu-icon"
              :color="layoutType === 'grid' ? 'emphasis' : 'default'"
              @icon-click="$emit('layout-menu-click', 'grid')"
            />
          </div>
          <div
            class="top-function-layout-menu-inner"
            :class="
              layoutType === 'list'
                ? 'top-function-layout-menu-inner-active'
                : ''
            "
          >
            <icons
              iconName="list"
              isButton
              class="top-function-layout-menu-icon"
              :color="layoutType === 'list' ? 'emphasis' : 'default'"
              @icon-click="$emit('layout-menu-click', 'list')"
            />
          </div>
          <div
            v-if="checkIncludeOptimization"
            class="top-function-layout-menu-inner"
            :class="
              layoutType === 'optimization'
                ? 'top-function-layout-menu-inner-active'
                : ''
            "
          >
            <icons
              iconName="optimization"
              isButton
              class="top-function-layout-menu-icon"
              :color="layoutType === 'optimization' ? 'emphasis' : 'default'"
              @icon-click="$emit('layout-menu-click', 'optimization')"
            />
          </div>
        </div>
      </div>
    </div>
  </page-top>
</template>

<script>
import icons from '@/components/atoms/icon'
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'
import fuseSearch from '@/components/molecules/fuse-search'
import menuList from '@/components/molecules/menu-list'

export default {
  components: {
    icons,
    pageTop,
    buttonMain,
    fuseSearch,
    menuList
  },
  data() {
    return {
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'accountId',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    accountInfo: Object,
    summary: Object,
    checked: Array,
    layoutType: String,
    project: Object,
    projectId: Number,
    allTrainedAILoading: Boolean,
    trainedAiFiltered: Array,
    checkIncludeOptimization: Boolean
  },
  computed: {
    targetTrainedAis() {
      return this.trainedAiFiltered.filter((ai) =>
        this.checked.some((id) => id === ai.id)
      )
    },
    haveAuthority() {
      if (this.accountInfo?.organizationInfo?.role === 'admin') return false
      if (this.accountInfo?.accountId && this.trainedAiFiltered) {
        return this.targetTrainedAis.some(
          (ai) => ai.accountId !== this.accountInfo.accountId
        )
      } else {
        return false
      }
    },
    checkServiceDisabledMulti() {
      if (!this?.targetTrainedAis) return true
      const disAvailableTypes = ['CLASSIFICATION', 'REGRESSION']
      return this.targetTrainedAis.some((item) => {
        return (
          disAvailableTypes.includes(item.type) &&
          item.predictionColumns.length > 1
        )
      })
    },
    checkOptimization() {
      if (!this?.targetTrainedAis) return true
      return this.targetTrainedAis.some((item) => {
        return item?.is_optimization
      })
    },
    checkRAG() {
      if (!this?.targetTrainedAis) return true
      return this.targetTrainedAis.some((item) => {
        return item?.type === 'RAG'
      })
    },
    checkServiceDisabledFix() {
      return this.checkOptimization
    },
    serviceDisabledTips() {
      if (this.checkOptimization) {
        return this.$t(
          'common.disabled.INFERENCE_SERVICE_NOT_AVAILABLE_OPTIMIZATION'
        )
      } else {
        return null
      }
    },
    checkNoDataset() {
      if (!this?.targetTrainedAis) return true
      return this.targetTrainedAis.some((item) => {
        return !item?.datasets || item.datasets.length === 0
      })
    },
    checkInferenceDisabledFix() {
      return this.checkOptimization && this.checkNoDataset
    },
    menus() {
      // サービス一覧に表示するアイコンメニュー
      let menuList = []
      if (!this.checkRAG) {
        menuList.push({
          id: 0,
          iconName: this.checkOptimization ? 'optimization' : 'inference', // inference
          value: 'inference',
          isMultiple: false, // 複数選択可能かどうか
          disabled: this.checkInferenceDisabledFix,
          tips: {
            name: this.checkOptimization
              ? this.$t('trainedAi.optimization.createNewOptimization')
              : this.$t('trainedAiList.menu.inference'),
            disabledName: this.$t('trainedAi.optimization.error.noDataOrRecipe')
          }
        })
      }
      if (this.accountInfo?.organizationInfo?.auth_services) {
        menuList.push({
          id: 1,
          iconName: 'service', // service
          value: 'service',
          isMultiple: false, // 複数選択可能かどうか
          disabled: this.checkServiceDisabledFix,
          tips: {
            name: this.$t('trainedAiList.menu.service'),
            disabledName: this.serviceDisabledTips
          }
        })
      }
      menuList = menuList.concat([
        {
          id: 2,
          iconName: 'projectMove', // project
          value: 'project',
          isMultiple: true, // 複数選択可能かどうか
          disabled: this.haveAuthority,
          tips: {
            name: this.$t('common.menu.projectMove'),
            disabledName: this.$t(
              'common.disabled.organization.otherAccountItem'
            )
          }
        },
        {
          id: 3,
          iconName: 'projectAdd', // project
          value: 'project-create',
          isMultiple: true, // 複数選択可能かどうか
          disabled: this.haveAuthority,
          tips: {
            name: this.$t('common.menu.projectCreateMove'),
            disabledName: this.$t(
              'common.disabled.organization.otherAccountItem'
            )
          }
        },
        {
          id: 4,
          iconName: 'delete', // delete
          value: 'delete',
          isMultiple: true, // 複数選択可能かどうか
          disabled: this.haveAuthority,
          tips: {
            name: this.$t('common.deleteButton'),
            disabledName: this.$t(
              'common.disabled.organization.otherAccountItemDelete'
            )
          }
        }
      ])
      return menuList
    }
  }
}
</script>

<style lang="scss" scoped>
.top-function {
  &-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-main {
    display: flex;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-search {
    width: adjustVW(320);
  }
  &-menus {
    display: flex;
    align-items: center;
    &-inner {
      padding: 0 $space-small 0 0;
      margin: 0 $space-small 0 0;
      border-right: $border-main;
    }
  }
  &-layout-menu {
    &-wrap {
      display: flex;
    }
    &-inner {
      position: relative;
      width: adjustVW(48);
      height: adjustVW(48);
      margin: 0 $space-base 0 0;
      background: $background-sub;
      border-radius: 9in;
      transition: all $transition-base;
      &:last-child {
        margin: 0;
      }
      &:hover {
        background: $medium-gray;
      }
      &-active {
        background: $key-lite;
        &:hover {
          background: $key-lite;
        }
      }
    }
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:hover ::v-deep button {
        opacity: 1;
      }
    }
  }
}
</style>
