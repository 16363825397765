<template>
  <div class="tab-content">
    <div class="item">
      <checkbox-base v-model="_removeHTML" />
      <texts
        class="figures-list-name-text"
        :text="$t('datasetList.popup.addDataset.removeHTML')"
        @click.native="toggle('_removeHTML')"
      />
    </div>
  </div>
</template>
<script>
import checkboxBase from '@/components/atoms/checkbox-base'

function createInners(names) {
  const res = {}
  names.forEach((name) => {
    res['_' + name] = {
      get() {
        return this.$props[name]
      },
      set(value) {
        this.$emit('update:' + name, value)
      }
    }
  })
  return res
}

export default {
  components: {
    checkboxBase
  },
  props: {
    removeHTML: Boolean
  },
  computed: {
    ...createInners(['removeHTML'])
  },
  methods: {
    toggle(name) {
      this[name] = !this[name]
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}
.item {
  display: flex;
}
</style>
