<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick || showPopup.includes('preventTrainingStatus')"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #trainingStop>
      <training-stop
        @close-modal="$emit('close-modal', $event)"
        @training-stop-confirm="$emit('training-stop-confirm')"
      />
    </template>
    <template #backgroundTrainingStop>
      <background-training-stop
        @close-modal="$emit('close-modal', $event)"
        @background-training-stop-confirm="
          $emit('background-training-stop-confirm')
        "
      />
    </template>
    <template #trainingGraph>
      <training-graph
        :graph="graph"
        :chartsData="chartsData"
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    <!--
    <template #statisticsGraph>
      <statistics-graph
        :graph="statisticsGraph"
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    -->
    <template #saveTrainedAi>
      <save-trained-ai
        :accountInfo="accountInfo"
        :loadingSave="loadingSave"
        :numOwntrainedAIs="numOwntrainedAIs"
        :numCreatingtrainedAIs="numCreatingtrainedAIs"
        :recipeType="recipeType"
        :sortedTrainedAis="sortedTrainedAis"
        :trainedAIFormValidate="createInfo.trainedAIFormValidate"
        :disableClick="disableClick"
        :checkOptimization="checkOptimization"
        @close-modal="$emit('close-modal', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @save-confirm="$emit('save-confirm', $event)"
        @discard-confirm="$emit('discard-confirm')"
        @save-this-trained-ai="$emit('save-this-trained-ai')"
      />
    </template>
    <template #saveTrainedAiComp>
      <save-trained-ai-comp
        :sortedTrainedAis="sortedTrainedAis"
        :checkOptimization="checkOptimization"
        @close-modal="$emit('re-train')"
        @move-inference="$emit('move-inference')"
        @re-train="$emit('re-train')"
        @move-this-project="$emit('move-this-project')"
        @move-optimization="$emit('move-optimization')"
      />
    </template>
    <template #limitTrainedAi>
      <limit-trained-ai
        :accountInfo="accountInfo"
        :numOwntrainedAIs="numOwntrainedAIs"
        :numCreatingtrainedAIs="numCreatingtrainedAIs"
        @close-modal="$emit('close-modal', $event)"
        @training-start-over="$emit('training-start-over')"
      />
    </template>
    <template #upperLimitTrainedAis>
      <trained-ai-list-popup-upper-limit-trained-ai
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    <template #deleteTrainedAi>
      <delete-trained-ai
        :loadingDelete="loadingDelete"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete-confirm="$emit('delete-confirm')"
      />
    </template>
    <template #noTrainingData>
      <no-training-data
        @close-modal="$emit('close-modal', $event)"
        @delete-confirm="$emit('delete-confirm')"
      />
    </template>
    <template #trainSettingError>
      <train-setting-error
        :type="trainSettingError"
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
    <template #preventTrainingStatus>
      <prevent-training-status />
    </template>
    <template #errorTraining>
      <error-training />
    </template>
    <template #timeTransformerV2BeforeSetting>
      <time-transformer-v2-before-setting
        :timeTransformerV2Settings="timeTransformerV2Settings"
        @select-time-transformer-setting="
          $emit('select-time-transformer-setting', $event)
        "
      />
    </template>
    <template #RAGConfigure>
      <RAGConfigure
        :datasetListBody="datasetListBody"
        :RAGresponse="RAGresponse"
        @test-connection="$emit('test-connection', $event)"
        @test-prompt="$emit('test-prompt', $event)"
        @rag-submit="$emit('rag-submit', $event)"
        @load-dataset-list="$emit('load-dataset-list', $event)"
        @add-new-data="$emit('add-new-data', $event)"
        @sort-label="$emit('sort-label', $event)"
        @show-dataset="$emit('show-dataset', $event)"
        @icon-menu-click="$emit('icon-menu-click', $event)"
      />
    </template>
    <template #addNewData>
      <popup-add-dataset
        v-bind="datasetListBody.addDataset"
        :accountInfo="accountInfo"
        :addNewData="addNewData"
        :fileInputValue="fileInputValue"
        :uploadLoading="datasetListBody.addDataset.uploadLoading"
        :resetDatasource="resetDatasource"
        :disableClick="disableClick"
        noDatasource
        @on-file-input="$emit('on-file-input', $event)"
        @close-modal="$emit('close-modal', $event)"
        @change-tab="$emit('change-tab', [$event, { target: addNewData }])"
        @text-input="$emit($event)"
        @datasource-input="$emit('datasource-input', $event)"
        @show-table="$emit('show-table', $event)"
        @start-uploading="$emit('start-uploading', $event)"
        @finished-uploading="$emit('finished-uploading')"
        @import-dataset-loading="$emit('import-dataset-loading', $event)"
        @datasource-items="$emit('datasource-items', $event)"
        @do-import="$emit('do-import', $event)"
        @file-clear="$emit('file-clear')"
        @test-datasource="$emit('test-datasource', $event)"
        @new-datasource="$emit('new-datasource', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @get-and-set-sql-list="$emit('get-and-set-sql-list', $event)"
        @get-sql-list="$emit('get-sql-list', $event)"
        @show-sql-edit="$emit('show-sql-edit', $event)"
        @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
        @set-datasource="$emit('set-datasource', $event)"
      />
      <div v-if="datasetListBody.addDataset.uploadLoading">
        <wait-setting-dataset
          :progressSettingDataset="datasetListBody.addDataset.progress"
          :progressSettingDatasetMax="datasetListBody.addDataset.maxProgress"
          :progressStatus="datasetListBody.addDataset.progressStatus"
          :uploadLearningDataWarnings="
            datasetListBody.addDataset.uploadLearningDataWarnings
          "
          isLargeCsv
        />
      </div>
    </template>
  </popup-array>
</template>

<script>
import { popupArray } from '@/components/molecules/popup'
import trainingStop from './popup/training-stop'
import trainingGraph from './popup/training-graph'
// import statisticsGraph from './popup/statistics-graph'
import saveTrainedAi from './popup/save-trained-ai'
import saveTrainedAiComp from './popup/save-trained-ai-comp'
import limitTrainedAi from './popup/limit-trained-ai'
import trainedAiListPopupUpperLimitTrainedAi from '@/components/organisms/trained-ai-list/trained-ai-list-popup-upper-limit-trained-ai'
import deleteTrainedAi from './popup/delete-trained-ai'
import noTrainingData from './popup/no-training-data'
import trainSettingError from './popup/train-setting-error'
import preventTrainingStatus from '@/components/organisms/common-popups/prevent-training-status.vue'
import BackgroundTrainingStop from './popup/background-training-stop.vue'
import errorTraining from './popup/error-training.vue'
import timeTransformerV2BeforeSetting from './popup/time-transformer-v2-before-setting'
import RAGConfigure from './popup/RAGConfigure/popup.vue'
// for rag dataset
import popupAddDataset from '@/components/organisms/dataset-list/popup/add-dataset'
// import popupDeleteData from './popup/delete-data.vue'
import waitSettingDataset from '@/components/organisms/wait-setting-dataset'

export default {
  components: {
    popupArray,
    trainingStop,
    trainingGraph,
    // statisticsGraph,
    saveTrainedAi,
    saveTrainedAiComp,
    limitTrainedAi,
    trainedAiListPopupUpperLimitTrainedAi,
    deleteTrainedAi,
    noTrainingData,
    trainSettingError,
    preventTrainingStatus,
    BackgroundTrainingStop,
    errorTraining,
    timeTransformerV2BeforeSetting,
    RAGConfigure,
    popupAddDataset,
    //    popupDeleteData,
    waitSettingDataset
  },
  computed: {
    popupDefs() {
      return {
        trainingStop: {
          title: this.$t('training.popup.titles.stopTraining')
        },
        backgroundTrainingStop: {
          title: this.$t('training.popup.titles.stopTraining')
        },
        trainingGraph: {
          title: this.$t('training.graph.title'),
          maxWidth: true,
          maxHeight: true
        },
        /**
         * TODO graphデータの情報を早く取得できるようになったら修正 https://trello.com/c/9gkhqjLK
        statisticsGraph: {
          title: this.$t('training.popup.statisticsGraph.title', { name: this.statisticsGraph.name }),
          maxHeight: true
        },
         */
        saveTrainedAi: {
          title: this.$t('training.popup.titles.saveTrainedAi')
        },
        saveTrainedAiComp: {
          title: this.$t('training.popup.titles.completeCreateAi')
        },
        limitTrainedAi: {
          title: this.$t('training.popup.titles.limitTrainedAi')
        },
        upperLimitTrainedAis: {
          title: this.$t('trainedAiList.upperLimit.title')
        },
        deleteTrainedAi: {
          title: this.$t('training.popup.titles.deleteTrainedAi')
        },
        noTrainingData: {
          title: this.$t('training.popup.titles.noTrainingData')
        },
        trainSettingError: {
          title: this.trainSettingErrorTitle
        },
        preventTrainingStatus: {
          title: this.$t('common.popup.preventTrainingStatus.title')
        },
        runningTrainingStop: {
          title: this.$t('training.popup.titles.stopTraining')
        },
        errorTraining: {
          title: this.$t('training.popup.errorTraining.title')
        },
        timeTransformerV2BeforeSetting: {
          title: this.$t('training.timeTransformerV2.setting.popupTitle')
        },
        RAGConfigure: {
          title: this.$t('training.popup.RAGConfigure.title'),
          maxWidth: true,
          maxHeight: true
        },
        addNewData: {
          title: this.$t('datasetList.popup.addDataset.title'),
          maxHeight: true
        }
      }
    },
    trainSettingErrorTitle() {
      if (this.trainSettingError === 'preprocessing') {
        return this.$t(
          'training.popup.trainSettingError.inPreprocessingDataset.title'
        )
      } else {
        return null
      }
    }
  },
  props: {
    accountInfo: Object,
    createInfo: Object,
    graph: String,
    // statisticsGraph: Object,
    trainSettingError: String,
    loadingSave: Boolean,
    loadingDelete: Boolean,
    numOwntrainedAIs: Number,
    numCreatingtrainedAIs: Number,
    recipeType: String,
    showPopup: Array,
    sortedTrainedAis: Array,
    disableClick: Boolean,
    chartsData: Object,

    runningJobs: Array,
    checkOptimization: Boolean,

    timeTransformerV2Settings: Array,

    datasetListBody: Object,
    addDataset: Object,
    RAGresponse: Object
  }
}
</script>
